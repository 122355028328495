<nav class="navbar navbar-expand-lg navbar-dark base-color fixed-top pe-2">
  <div class="container">
    <div class="d-flex w-100">
      <img class="img-fluid logo" src="../../../assets/images/phoenix-logo-wht-lg.png" />

      <div class="flex-grow-1">

        <div class="flex-fill">
          <a class="navbar-brand" href="#" [routerLink]="['/home']" class="logo-name align-middle">
            Phoenix Blinds & Shutters
          </a>
          <br />
          <a class="logo-name" href="tel:(023)92 82 99 33">(023)92 82 99 33</a>
        </div>

      </div>

      <div class="flex-fill d-none d-sm-block align-self-center">
        <a [routerLink]="['/shutter-quote']" class="btn btn-success"><span class="text-white">GET A QUOTE</span></a>
      </div>

      <button class="navbar-toggler me-auto" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="fa-solid fa-bars"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Links -->
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item active">
          <a class="nav-link waves-light cws-blue" [routerLink]="['/home']">
            <i class="fa-solid fa-home text-white"></i> Home
          </a>
        </li>

        <li class="nav-item active">
          <a class="nav-link waves-light cws-blue" [routerLink]="['/shutters']">
            <i class="fa-solid fa-th-large text-white"></i> Shutters
          </a>
        </li>
        <li class="dropdown">
          <div class="nav-link waves-light dropdown-toggle cws-blue" type="button" data-bs-toggle="dropdown"
            aria-expanded="false" class="nav-link waves-light dropdown-toggle cws-blue">
            <i class="fa-solid fa-bars text-white"></i> Blinds
          </div>
          <ul class="dropdown-menu">
            <a class="dropdown-item waves-light" [routerLink]="['/dijon-blinds']">Dijon Blinds</a>
            <a class="dropdown-item waves-light" [routerLink]="['/vertical']">Vertical</a>
            <a class="dropdown-item waves-light" [routerLink]="['/roller']">Roller</a>
            <a class="dropdown-item waves-light" [routerLink]="['/venetian']">Venetian</a>
            <a class="dropdown-item waves-light" [routerLink]="['/pleated']">Pleated</a>
            <a class="dropdown-item waves-light" [routerLink]="['/conservatory']">Conservatory</a>
            <a class="dropdown-item waves-light" [routerLink]="['/perfect-fit']">Perfect-Fit</a>
            <!-- <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]='["/intu"]'>Intu</a> -->
            <a class="dropdown-item waves-light" [routerLink]="['/shutters']">Shutters</a>
            <a class="dropdown-item waves-light" [routerLink]="['/shutter-quote']">Shutter Quote</a>
          </ul>
        </li>

        <li class="nav-item active">
          <a class="nav-link waves-light cws-blue" [routerLink]="['/contact-us']">
            <i class="fa-solid fa-envelope text-white"></i> Contact
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light cws-blue" [routerLink]="['/blogs']">
            <i class="fa-solid fa-blog text-white"></i> Blogs
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>