import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';

import { Amplify } from '@aws-amplify/core';
import awsconfig from 'src/aws-exports';
import { AppComponent } from './app/app.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { PopupService } from './app/services/popup.service';
import { DataService } from './app/services/data.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

Amplify.configure(awsconfig);

if (environment.production) {
  enableProdMode();
}

// import {Amplify} from '@aws-amplify/core';

// import aws_exports from './aws-exports';

// Amplify.configure(aws_exports);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, NgxMasonryModule, ReactiveFormsModule),
        DataService,
        PopupService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()), 
        provideAnimationsAsync()
    ]
})
  .catch(err => console.error(err));
