import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";

const routerOptions: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
  onSameUrlNavigation: "reload",

  enableTracing: false,
};

const routes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: "home",
    loadComponent: () =>
      import("./pages/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: "privacy",
    loadComponent: () =>
      import("./pages/privacy/privacy.component").then(
        (c) => c.PrivacyComponent
      ),
  },
  {
    path: "roller",
    loadComponent: () =>
      import("./pages/roller/roller.component").then((c) => c.RollerComponent),
  },
  {
    path: "pleated",
    loadComponent: () =>
      import("./pages/pleated/pleated.component").then(
        (c) => c.PleatedComponent
      ),
  },
  {
    path: "vertical",
    loadComponent: () =>
      import("./pages/vertical/vertical.component").then(
        (c) => c.VerticalComponent
      ),
  },
  {
    path: "venetian",
    loadComponent: () =>
      import("./pages/venetian/venetian.component").then(
        (c) => c.VenetianComponent
      ),
  },
  {
    path: "perfect-fit",
    loadComponent: () =>
      import("./pages/perfect-fit/perfect-fit.component").then(
        (c) => c.PerfectFitComponent
      ),
  },
  {
    path: "conservatory",
    loadComponent: () =>
      import("./pages/conservatory/conservatory.component").then(
        (c) => c.ConservatoryComponent
      ),
  },
  {
    path: "spares",
    loadComponent: () =>
      import("./pages/spares/spares.component").then((c) => c.SparesComponent),
  },
  {
    path: "curved",
    loadComponent: () =>
      import("./pages/curved/curved.component").then((c) => c.CurvedComponent),
  },
  {
    path: "shutters",
    loadComponent: () =>
      import("./pages/shutters/shutters.component").then(
        (c) => c.ShuttersComponent
      ),
  },
  {
    path: "shutter-quote",
    loadComponent: () =>
      import("./pages/shutter-quote/shutter-quote.component").then(
        (c) => c.ShutterQuoteComponent
      ),
  },
  {
    path: "intu",
    loadComponent: () =>
      import("./pages/intu/intu.component").then((c) => c.IntuComponent),
  },
  {
    path: "blogs",
    loadComponent: () =>
      import("./pages/blog/blog.component").then((c) => c.BlogComponent),
  },
  {
    path: "blog/:blogId",
    loadComponent: () =>
      import("./pages/blog-full-post/blog-full-post.component").then(
        (c) => c.BlogFullPostComponent
      ),
  },
  {
    path: "love-shutters",
    loadComponent: () =>
      import("./pages/offer-landing-page/offer-landing-page.component").then(
        (c) => c.OfferLandingPageComponent
      ),
  },
  {
    path: "shutter-offer",
    loadComponent: () =>
      import("./pages/offer-landing-page/offer-landing-page.component").then(
        (c) => c.OfferLandingPageComponent
      ),
  },
  {
    path: "chichester",
    loadComponent: () =>
      import(
        "./pages/chichester-landing-page/chichester-landing-page.component"
      ).then((c) => c.ChichesterLandingPageComponent),
  },
  {
    path: "petersfield",
    loadComponent: () =>
      import(
        "./pages/petersfield-landing-page/petersfield-landing-page.component"
      ).then((c) => c.PetersfieldLandingPageComponent),
  },
  {
    path: "contact-us",
    loadComponent: () =>
      import("./pages/contact-us/contact-us.component").then(
        (c) => c.ContactUsComponent
      ),
  },
  {
    path: "dijon-blinds",
    loadComponent: () =>
      import("./pages/dijon/dijon.component").then((c) => c.DijonComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
